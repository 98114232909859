import { useLocation } from 'react-router-dom';
import { IntroHeadline } from 'shared/components/Text';
import IntroPageGroup from 'shared/components/IntroPageGroup';
import TransitionLayout from 'shared/components/Layouts/TransitionLayout';
import TeamUpdateGif from 'shared/static/images/team-update.gif';

const TeamUpdateIntro = () => {
  const { pathname } = useLocation();

  return (
    <TransitionLayout>
      <IntroPageGroup
        imagePath={TeamUpdateGif}
        path={`${pathname}/breakout-rooms`}
        headline={<IntroHeadline>Team Update</IntroHeadline>}
      />
    </TransitionLayout>
  );
};

export default TeamUpdateIntro;
