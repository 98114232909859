export const HighlightsIcebreakers = [
  {
    title: 'Weekend Highlights & Icebreaker',
    time: '10-15 minutes',
    type: 'weekend-updates',
  },
  {
    title: 'Sprint Check-in Reminder',
    time: '30 seconds',
    type: 'sprint-point-reminder',
  },
  { title: 'Sprint Streak Winners', time: '1 minute', type: 'streak-winners' },
  {
    title: 'Yeti Shoutouts & Updates',
    time: '3-5 minutes',
    type: 'shoutouts',
  },
  { title: 'Team Cheer of the Day!', time: '30 seconds', type: 'cheer' },
];
