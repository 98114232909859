import { ease } from './animations/constants';
import { AnimationType, Delay } from './animations/types';
import { Variants } from 'framer-motion';
import { secondaryBlue, white, tertiaryBlue, primaryBlue } from './colors';
import theme from './theme';

export const fadeIntoNextComponent: Variants = {
  fadeOut: {
    position: 'absolute',
    zIndex: theme.zIndex.pageTransition,
    opacity: 0,
    transition: {
      duration: 0.5,
      easeInOut: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

export const slideIntoNextComponent: Variants = {
  initial: {
    position: 'absolute',
    x: '50%',
    transition: {
      duration: 1,
      type: 'linear',
    },
  },
  slideIn: {
    position: 'absolute',
    x: '0%',
    transition: {
      duration: 1,
      type: 'linear',
    },
  },
  slideOut: {
    position: 'absolute',
    zIndex: 10,
    x: '-100%',
    transition: {
      duration: 1,
      type: 'linear',
    },
  },
};

export const agendaCard: Variants = {
  initial: {
    backgroundColor: secondaryBlue,
    boxShadow: 'none',
    transition: {
      type: 'easeInOut',
      duration: 0.5,
      delay: 0.5,
    },
  },
  enter: {
    backgroundColor: white,
    boxShadow: theme.shadows[1],
    transition: {
      type: 'easeInOut',
      duration: 0.5,
    },
  },
};

export const agendaLine: Variants = {
  initial: {
    backgroundColor: tertiaryBlue,
    transition: {
      type: 'easeInOut',
      duration: 0.5,
      delay: 0.5,
    },
  },
  enter: {
    backgroundColor: primaryBlue,
    transition: {
      type: 'easeInOut',
      duration: 0.5,
    },
  },
};

export const contentFade = {
  initial: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
      type: 'linear',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.5,
      type: 'linear',
    },
  },
};

export const enterDownExitDown: Variants = {
  [AnimationType.Initial]: { y: -30, opacity: 0 },
  [AnimationType.Exit]: (i: Delay) => ({
    y: 50,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease,
      delay: i.exitDelay ?? 0,
    },
  }),
  [AnimationType.Enter]: (i: Delay) => ({
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      ease,
      delay: i.enterDelay ?? 0,
    },
  }),
};

export const enterUpExitDown: Variants = {
  [AnimationType.Initial]: { y: 30, opacity: 0 },
  [AnimationType.Exit]: (i: Delay) => ({
    y: -30,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease,
      delay: i.exitDelay ?? 0,
    },
  }),
  [AnimationType.Enter]: (i: Delay) => ({
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      ease,
      delay: i.enterDelay ?? 0,
    },
  }),
};

export const enterLeftExitDown: Variants = {
  [AnimationType.Initial]: { y: 0, opacity: 0, x: -20 },
  [AnimationType.Exit]: (i: Delay) => ({
    y: 20,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease,
      delay: i.exitDelay ?? 0,
    },
  }),
  [AnimationType.Enter]: (i: Delay) => ({
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      ease,
      delay: i.enterDelay ?? 0,
    },
  }),
};
