export const FunActivity = [
  {
    title: 'Social Hang in Breakout Rooms',
    time: '5 minutes',
    type: 'breakout-rooms',
  },
  {
    title: 'Sprint Check-in Reminder',
    time: '30 seconds',
    type: 'sprint-point-reminder',
  },
  {
    title: 'Yeti Shoutouts & Updates',
    time: '0-5 minutes',
    type: 'shoutouts',
  },
  { title: 'Fun Activity', time: '10-15 minutes', type: 'activity' },
  { title: 'Team Cheer of the Day!', time: '30 seconds', type: 'cheer' },
];
