import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import theme from 'shared/styles/theme';
import { ButtonSecondary } from 'shared/components/Button';
import { Stack } from '@mui/material';
import { H1, Copy } from 'shared/components/Text';
import SplitLayout from 'shared/components/Layouts/SplitLayout';
import MainLayout from 'shared/components/Layouts/Main';
import { ROUTES } from 'shared/constants';
import StreakWinnersModal from 'shared/components/StreakWinners/StreakWinnersModal';
import { useState } from 'react';
import ReactConfetti from 'react-confetti';
import { useQuery } from '@apollo/client';
import { PARTY_PARROTS } from './query.partyParrots';
import { primaryBlue } from 'shared/styles/colors';
import Spinner from 'react-spinkit';

const PartyParrot = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { data, loading, error } = useQuery(PARTY_PARROTS);

  const buttonContent = loading ? (
    <Spinner name="pacman" color={primaryBlue} />
  ) : (
    'Continue'
  );

  const onContinueClick = () => navigate(ROUTES.PARTY_PARROT.CHEER);

  return (
    <MainLayout>
      <>
        <SplitLayout>
          <ReactConfetti />
          {data && (
            <StreakWinnersModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              streaks={data?.partyParrots}
              onContinueClick={onContinueClick}
            />
          )}
          <Stack alignItems="center" maxWidth={600}>
            <H1 lineHeight={1.21}>Party Parrots</H1>
            <Text>
              Go winners! Continue on track for more chances to get your desired
              party parrot.
            </Text>
            <ButtonSecondary onClick={onContinueClick}>
              {buttonContent}
            </ButtonSecondary>
          </Stack>
        </SplitLayout>
      </>
    </MainLayout>
  );
};

export default PartyParrot;

const Text = styled(Copy)({
  margin: theme.spacing(2, 0, 6.5),
});

const TextLink = styled.button({
  textDecoration: 'underline',
  fontWeight: 600,
  fontSize: 'inherit',
  border: 'none',
  background: 'none',
  color: 'inherit',
});
