import { gql } from '@apollo/client';

export const SOCIAL_CURSOR = gql`
  subscription LiveMouseTracking {
    liveMouseTracking {
      userName
      color
      icon
      pageX
      pageY
    }
  }
`;
