export const TeamUpdate = [
  {
    title: 'Social Hang in Breakout Rooms',
    time: '10 minutes',
    type: 'breakout-rooms',
  },
  {
    title: 'Sprint Check-in Reminder',
    time: '30 seconds',
    type: 'sprint-point-reminder',
  },
  { title: 'Yeti Shoutouts & Updates', time: '0-5 minutes', type: 'shoutouts' },
  {
    title: 'Team Project Update + Q&A',
    time: '5 minutes',
    type: 'project-update',
  },
  { title: 'Team Cheer of the Day!', time: '30 seconds', type: 'cheer' },
];
