import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { primaryBlue, white, secondaryBlue } from 'shared/styles/colors';
import { shadowCard } from 'shared/styles/shadows';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }
  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
  }

  interface ZIndex {
    mobileStepper: number;
    speedDial: number;
    appBar: number;
    drawer: number;
    modal: number;
    snackbar: number;
    tooltip: number;
    pageTransition: number;
    carouselArrow: number;
  }
  // interface ZIndexOptions {
  //   pageStepper: number;
  // }

  interface Duration {
    shortest: number;
    shorter: number;
    short: number;
    standard: number;
    complex: number;
    enteringScreen: number;
    leavingScreen: number;
    mid: number;
  }
}

let theme = createTheme({
  palette: {
    primary: {
      main: primaryBlue,
    },
    secondary: {
      main: white,
    },
    tertiary: {
      main: secondaryBlue,
    },
    text: {
      primary: primaryBlue,
      secondary: white,
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif;",
  },
  zIndex: {
    pageTransition: 1600,
    carouselArrow: 1700,
  },
  transitions: {
    duration: {
      mid: 500,
    },
  },
});

const shadows = theme.shadows;
shadows[1] = shadowCard;
theme = responsiveFontSizes(theme);

export default theme;
