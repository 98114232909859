import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { CREATE_NEW_SPRINT } from 'screens/SprintCheckIn/components/mutation.sprints';
import Modal from 'shared/components/Modal';
import { H2V2, Subheading } from 'shared/components/Text';
import TikiHaltIcon from 'shared/static/images/TikiHaltIcon.png';
import {
  beige,
  black,
  buttonGrey,
  lightBlack,
  lightBrown,
  white,
} from 'shared/styles/colors';

interface Props {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const StartNewSprintModal = ({ isOpen, setOpen }: Props) => {
  const [createNewSprint] = useMutation(CREATE_NEW_SPRINT, {
    refetchQueries: ['Projects'],
  });

  const onClickProceed = () => {
    createNewSprint();
    setOpen(false);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Modal
      handleOpen={isOpen}
      handleClose={closeModal}
      modalContentStyles={modalStyles}
      closeIconColor={black}
    >
      <Heading>Halt!</Heading>
      <TikiIcon src={TikiHaltIcon} />
      <SubHeader>
        Are you sure you want to end the current sprints and start new ones?
      </SubHeader>
      <BodyText>This action cannot be undone.</BodyText>
      <NewSprintButton variant="contained" onClick={onClickProceed}>
        PROCEED
      </NewSprintButton>
      <UnderlineButton variant="text" onClick={closeModal}>
        Nevermind
      </UnderlineButton>
    </Modal>
  );
};

export default StartNewSprintModal;

const modalStyles = css({
  border: `solid 3px ${black}`,
  backgroundColor: beige,
});

const Heading = styled(H2V2)({
  fontSize: 48,
  margin: '0 0 15px',
});

const SubHeader = styled(H2V2)({
  fontSize: 24,
  maxWidth: 384,
  margin: '24px 0 0',
  textAlign: 'center',
});

const BodyText = styled(Subheading)({
  fontSize: 16,
  color: black,
  maxWidth: 384,
  margin: '20px 0 24px',
  textAlign: 'center',
});

const NewSprintButton = styled(Button)({
  width: '170px',
  height: '52px',
  flexGrow: 0,
  fontWeight: 600,
  borderRadius: '30px',
  backgroundColor: buttonGrey,
  color: white,
  '&:hover': {
    backgroundColor: lightBrown,
  },
});

const UnderlineButton = styled(Button)({
  color: lightBlack,
  fontSize: 15,
  fontWeight: 800,
  marginTop: '30px',
  textDecoration: 'underline',
  textTransform: 'none',
});

const TikiIcon = styled.img({
  width: '230px',
  height: '179px',
});
