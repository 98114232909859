import theme from 'shared/styles/theme';

export const buttonTransition = theme.transitions.create(
  ['color', 'border-color', 'background-color'],
  {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  }
);

export const cardTransition = theme.transitions.create(
  ['color', 'background-color'],
  {
    duration: theme.transitions.duration.mid,
    easing: theme.transitions.easing.easeInOut,
  }
);

export const agendaTransition = theme.transitions.create(['all'], {
  duration: theme.transitions.duration.mid,
  easing: theme.transitions.easing.easeInOut,
});
