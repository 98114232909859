import { gql } from '@apollo/client';

export const PROJECTS = gql`
  query Projects {
    projects {
      name
      isActive
      id
      teamUpdate
      assignee
      currentSprint {
        id
        totalPoints
        completedPoints
        currentSprintDay {
          id
          date
          updatedAt
        }
      }
    }
  }
`;
