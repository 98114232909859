import styled from '@emotion/styled';
import { useEffect } from 'react';
// @ts-ignore
import airHorn from 'shared/static/audio/airHorn.mp3';
import useSound from 'use-sound';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const AirHornButton = () => {
  const [play, { stop }] = useSound(airHorn);

  const onClick = () => {
    stop();
    play();
  };

  return (
    <IconContainer>
      <VolumeUpIcon onClick={onClick}>Smash</VolumeUpIcon>
    </IconContainer>
  );
};

export default AirHornButton;

const IconContainer = styled.div({
  position: 'absolute',
  top: 28,
  left: 26,
  cursor: 'pointer',
});
