import { gql } from '@apollo/client';

export const UPDATE_MOUSE_POSITION = gql`
  mutation Mutation(
    $userName: String
    $color: String
    $icon: String
    $pageX: Float
    $pageY: Float
  ) {
    updateMousePosition(
      userName: $userName
      color: $color
      icon: $icon
      pageX: $pageX
      pageY: $pageY
    ) {
      userName
      color
      icon
      pageX
      pageY
    }
  }
`;
