import { ROUTES } from 'shared/constants';

import Home from 'screens/Home';
import HighlightsIcebreakers from 'screens/HighlightsIcebreakers/HighlightsIcebreakers';
import HighlightsIcebreakersIntro from 'screens/HighlightsIcebreakers/HighlightsIcebreakersIntro';
import FunActivity from 'screens/FunActivity/FunActivity';
import FunActivityIntro from 'screens/FunActivity/FunActivityIntro';
import TeamUpdate from 'screens/TeamUpdate/TeamUpdate';
import TeamUpdateIntro from 'screens/TeamUpdate/TeamUpdateIntro';
import PartyParrots from 'screens/PartyParrot/PartyParrots';
import PartyParrotIntro from 'screens/PartyParrot/PartyParrotIntro';
import Cheer from 'screens/Cheer';
import Shoutouts from 'screens/Shoutouts';
import BreakoutRooms from 'screens/BreakoutRooms';
import SprintCheckIn from 'screens/SprintCheckIn/SprintCheckIn';
import SprintCheckInV2 from 'screens/SprintCheckInV2/SprintCheckInV2';
import SprintPointReminder from 'screens/SprintPointReminder';
import StreakWinners from 'screens/StreakWinners/StreakWinners';

export const routes = [
  {
    path: ROUTES.HOME,
    element: <Home />,
  },
  {
    path: ROUTES.SPRINT_CHECK_IN,
    element: <SprintCheckInV2 />,
  },
  {
    path: ROUTES.HIGHLIGHTS_ICEBREAKERS.ROOT,
    element: <HighlightsIcebreakersIntro />,
  },
  {
    path: ROUTES.HIGHLIGHTS_ICEBREAKERS.WEEKEND_UPDATES,
    element: <HighlightsIcebreakers />,
  },
  {
    path: ROUTES.HIGHLIGHTS_ICEBREAKERS.SPRINT_POINT_REMINDER,
    element: (
      <SprintPointReminder
        path={ROUTES.HIGHLIGHTS_ICEBREAKERS.STREAK_WINNERS}
      />
    ),
  },
  {
    path: ROUTES.HIGHLIGHTS_ICEBREAKERS.STREAK_WINNERS,
    element: <StreakWinners path={ROUTES.HIGHLIGHTS_ICEBREAKERS.SHOUTOUTS} />,
  },
  {
    path: ROUTES.HIGHLIGHTS_ICEBREAKERS.SHOUTOUTS,
    element: <Shoutouts path={ROUTES.HIGHLIGHTS_ICEBREAKERS.CHEER} />,
  },
  {
    path: ROUTES.HIGHLIGHTS_ICEBREAKERS.CHEER,
    element: <Cheer />,
  },
  {
    path: ROUTES.FUN_ACTIVITY.ROOT,
    element: <FunActivityIntro />,
  },
  {
    path: ROUTES.FUN_ACTIVITY.BREAKOUT_ROOMS,
    element: <BreakoutRooms path={ROUTES.FUN_ACTIVITY.SPRINT_POINT_REMINDER} />,
  },
  {
    path: ROUTES.FUN_ACTIVITY.SPRINT_POINT_REMINDER,
    element: <SprintPointReminder path={ROUTES.FUN_ACTIVITY.SHOUTOUTS} />,
  },
  {
    path: ROUTES.FUN_ACTIVITY.SHOUTOUTS,
    element: <Shoutouts path={ROUTES.FUN_ACTIVITY.ACTIVITY} />,
  },
  {
    path: ROUTES.FUN_ACTIVITY.CHEER,
    element: <Cheer />,
  },
  {
    path: ROUTES.FUN_ACTIVITY.ACTIVITY,
    element: <FunActivity />,
  },
  {
    path: ROUTES.FUN_ACTIVITY.CHEER,
    element: <Cheer />,
  },
  {
    path: ROUTES.TEAM_UPDATE.ROOT,
    element: <TeamUpdateIntro />,
  },
  {
    path: ROUTES.TEAM_UPDATE.BREAKOUT_ROOMS,
    element: <BreakoutRooms path={ROUTES.TEAM_UPDATE.SPRINT_POINT_REMINDER} />,
  },
  {
    path: ROUTES.TEAM_UPDATE.SPRINT_POINT_REMINDER,
    element: <SprintPointReminder path={ROUTES.TEAM_UPDATE.SHOUTOUTS} />,
  },
  {
    path: ROUTES.TEAM_UPDATE.SHOUTOUTS,
    element: <Shoutouts path={ROUTES.TEAM_UPDATE.PROJECT_UPDATE} />,
  },
  {
    path: ROUTES.TEAM_UPDATE.PROJECT_UPDATE,
    element: <TeamUpdate />,
  },
  {
    path: ROUTES.TEAM_UPDATE.CHEER,
    element: <Cheer />,
  },
  {
    path: ROUTES.PARTY_PARROT.ROOT,
    element: <PartyParrotIntro />,
  },
  {
    path: ROUTES.PARTY_PARROT.PARTY_PARROTS,
    element: <PartyParrots />,
  },
  {
    path: ROUTES.PARTY_PARROT.BREAKOUT_ROOMS,
    element: <BreakoutRooms path={ROUTES.PARTY_PARROT.SPRINT_POINT_REMINDER} />,
  },
  {
    path: ROUTES.PARTY_PARROT.SPRINT_POINT_REMINDER,
    element: <SprintPointReminder path={ROUTES.PARTY_PARROT.SHOUTOUTS} />,
  },
  {
    path: ROUTES.PARTY_PARROT.SHOUTOUTS,
    element: <Shoutouts path={ROUTES.PARTY_PARROT.PARTY_PARROTS} />,
  },
  {
    path: ROUTES.PARTY_PARROT.CHEER,
    element: <Cheer />,
  },
];
