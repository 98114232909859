import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import theme from 'shared/styles/theme';
import { ButtonSecondary } from 'shared/components/Button';
import { Stack, Grid } from '@mui/material';
import { H1, Copy, MontserratBold } from 'shared/components/Text';
import SplitLayout from 'shared/components/Layouts/SplitLayout';
import MainLayout from 'shared/components/Layouts/Main';
import { isToday, parseISO } from 'date-fns';
import { CheckmarkIcon } from 'shared/components/icons';
import { ProjectsQuery } from 'shared/types/typedDocumentNodes';
import { useQuery } from '@apollo/client';
import { PROJECTS } from 'shared/queries/queries.projects';
import Spinner from 'react-spinkit';
import { primaryBlue } from 'shared/styles/colors';

interface SprintPointReminderProps {
  path: string;
}

const SprintPointReminder = ({ path }: SprintPointReminderProps) => {
  const navigate = useNavigate();
  const [projectList, setProjectList] = useState<
    ProjectsQuery['projects'] | null
  >(null);

  // TODO: Adding error messaging
  const { loading, error, startPolling, stopPolling } = useQuery(PROJECTS, {
    fetchPolicy: 'no-cache',
    onCompleted: async ({ projects }: ProjectsQuery) => {
      setProjectList(projects);
    },
    notifyOnNetworkStatusChange: true,
  });

  const POLL_INTERVAL = 2000;

  useEffect(() => {
    startPolling(POLL_INTERVAL);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling, POLL_INTERVAL]);

  let content;
  if (loading) {
    content = <Spinner name="line-scale" color={primaryBlue} />;
  }

  const projects = projectList?.map((project, index) => {
    const name = project?.name;
    const currentSprint = project?.currentSprint;
    const currentSprintDay = currentSprint?.currentSprintDay;
    const sprintUpdateDate = parseISO(currentSprintDay?.updatedAt);
    const updateCompleted = sprintUpdateDate ? isToday(sprintUpdateDate) : null;

    if (projectList) {
      content = (
        <>
          <CheckmarkContainer visible={Boolean(updateCompleted)}>
            <CheckmarkIcon />
          </CheckmarkContainer>
          <ProjectName>{name}</ProjectName>
        </>
      );
    }
    return (
      <Grid
        container
        item
        xs={4}
        key={`${name}-${index}`}
        direction="row"
        alignItems="center"
      >
        {content}
      </Grid>
    );
  });

  return (
    <MainLayout>
      <SplitLayout>
        <Stack alignItems="center" maxWidth={600}>
          <H1 lineHeight={1.21}>Sprint Check-in</H1>
          <Text>
            Reminder to complete your daily <br /> sprint check-in
          </Text>
          <Grid container columnGap={0} rowGap={2}>
            {projects}
          </Grid>
          <Button onClick={() => navigate(path)}>Continue</Button>
        </Stack>
      </SplitLayout>
    </MainLayout>
  );
};

export default SprintPointReminder;

const Text = styled(Copy)({
  margin: theme.spacing(2, 0, 4),
});

const CheckmarkContainer = styled.span<{ visible: boolean }>(({ visible }) => ({
  visibility: visible ? 'visible' : 'hidden',
}));

const ProjectName = styled.p([
  MontserratBold,
  {
    fontSize: theme.typography.pxToRem(16),
    marginLeft: theme.spacing(1.25),
  },
]);

const Button = styled(ButtonSecondary)({
  marginTop: theme.spacing(7),
});
