import styled from '@emotion/styled';
import { Button, Grid } from '@mui/material';
import {
  buttonGrey,
  lightBlack,
  lightBrown,
  summerOrange,
} from 'shared/styles/colors';
import { H2V2, Subheading } from 'shared/components/Text';
import TransitionLayout from 'shared/components/Layouts/TransitionLayout';
import BackgroundImage from 'shared/static/images/background.png';
import AdrenalineLogo from 'shared/static/images/adrenaline-logo.png';
import WalletIcon from 'shared/static/images/WalletIcon.png';
import MountainIcon from 'shared/static/images/MountainIcon.png';
import ProjectBox from './components/ProjectBox';
import { useEffect, useState } from 'react';
import ConfigureProfileModal from './components/ConfigureProfileModal';
import { Circle } from './components/styledComponents';
import { ProjectsQuery } from 'shared/types/typedDocumentNodes';
import { useMutation, useQuery } from '@apollo/client';
import { PROJECTS } from 'shared/queries/queries.projects';
import Spinner from 'react-spinkit';
import { isThisWeek, parseISO } from 'date-fns';
import CookiesService, { Identifiers } from 'services/Cookie.service';
import useUpdateMousePosition from 'shared/hooks/useMousePosition';
import { useLiveEventsContext } from 'context/liveEvents';
import { UPDATE_MOUSE_POSITION } from 'shared/mutations/mutations.liveEvents';
import { useInterval } from 'shared/hooks/useInterval';
import StartNewSprintModal from './components/StartNewSprintModal';

interface MousePosition {
  pageX: number;
  pageY: number;
}

const SprintCheckInV2 = () => {
  const { setUserToFilter } = useLiveEventsContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSprintModalOpen, setIsSprintModalOpen] = useState<boolean>(false);
  const [userColor, setUserColor] = useState<string>('');
  const [userIcon, setUserIcon] = useState<any>(null);
  const [userName, setUserName] = useState<string>('');
  const [projectList, setProjectList] = useState<
    ProjectsQuery['projects'] | null
  >(null);
  const [latestMousePosition, setLatestMousePosition] = useState<MousePosition>(
    { pageX: 0, pageY: 0 }
  );

  const [mouseHasMoved, setMouseHasMoved] = useState<boolean>(false);
  const [updateMousePosition] = useMutation(UPDATE_MOUSE_POSITION);

  const { error, loading, data, startPolling } = useQuery(PROJECTS, {
    onCompleted: async ({ projects }: ProjectsQuery) => {
      setProjectList(projects);
    },
  });

  useEffect(() => {
    startPolling(500);
  }, [data]);

  useUpdateMousePosition({
    userName,
    color: userColor,
    icon: userIcon,
    setLatestMousePosition,
    setMouseHasMoved,
  });

  useInterval(() => {
    if (!mouseHasMoved) {
      updateMousePosition({
        variables: {
          userName,
          color: userColor,
          icon: userIcon,
          pageX: latestMousePosition.pageX,
          pageY: latestMousePosition.pageY,
        },
      });
    }
    setMouseHasMoved(false);
  }, 3000);

  useEffect(() => {
    const userIconFromCookies = CookiesService.getCookie(Identifiers.UserIcon);
    const userNameFromCookies = CookiesService.getCookie(Identifiers.UserName);
    const userColorFromCookies = CookiesService.getCookie(
      Identifiers.UserColor
    );
    if (userIconFromCookies && userNameFromCookies && userColorFromCookies) {
      setUserIcon(userIconFromCookies);
      setUserName(userNameFromCookies);
      setUserColor(userColorFromCookies);
      setUserToFilter(userNameFromCookies);
    } else {
      setIsModalOpen(true);
    }
  }, []);

  return (
    <TransitionLayout>
      <ContainerGrid>
        <StartNewSprintModal
          isOpen={isSprintModalOpen}
          setOpen={setIsSprintModalOpen}
        />
        <ConfigureProfileModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          userColor={userColor}
          userName={userName}
          userIcon={userIcon}
          setUserColor={setUserColor}
          setUserIcon={setUserIcon}
          setUserName={setUserName}
        />
        <HeaderContainer>
          <HeaderItemContainer>
            <Logo src={AdrenalineLogo} />
          </HeaderItemContainer>
          <CenterItem>
            <Mountain src={MountainIcon} />
            <Heading>Check-In</Heading>
          </CenterItem>
          <HeaderItemContainer>
            {userIcon && userName && userColor && !isModalOpen ? (
              <>
                <NameText>{userName}</NameText>
                <IconCircle
                  onClick={() => setIsModalOpen(true)}
                  backgroundColor={userColor}
                >
                  <Icon src={userIcon} />
                </IconCircle>
              </>
            ) : (
              <Wallet src={WalletIcon} />
            )}
          </HeaderItemContainer>
        </HeaderContainer>
        <SubHeader>
          Select your project and update your weekly sprint points
        </SubHeader>
        <SprintButton
          variant="contained"
          onClick={() => setIsSprintModalOpen(true)}
        >
          New Sprint
        </SprintButton>
        {loading ? (
          <LoadingContainer>
            <LargeSpinner name="line-scale-pulse-out" color={summerOrange} />
          </LoadingContainer>
        ) : error ? (
          <LoadingContainer>
            <SubHeader>
              An error has occurred. Projects are unable to be loaded.
            </SubHeader>
          </LoadingContainer>
        ) : (
          <ProjectsContainer>
            {projectList &&
              projectList.map((project, index) => (
                <ProjectBox
                  hasUpdate={
                    project?.teamUpdate
                      ? isThisWeek(parseISO(project.teamUpdate))
                      : false
                  }
                  projectName={project?.name ?? 'Unnamed'}
                  assignee={project?.assignee ?? 'Unknown'}
                  sprintGoal={project?.currentSprint?.totalPoints ?? 0}
                  sprintPointsCompleted={
                    project?.currentSprint?.completedPoints ?? 0
                  }
                  sprintId={project?.currentSprint?.id ?? null}
                  sprintDay={project?.currentSprint?.currentSprintDay ?? null}
                  key={index}
                />
              ))}
          </ProjectsContainer>
        )}
      </ContainerGrid>
    </TransitionLayout>
  );
};

export default SprintCheckInV2;

const ContainerGrid = styled(Grid)({
  display: 'flex',
  backgroundImage: `url(${BackgroundImage})`,
  flexDirection: 'column',
  alignItems: 'center',
  padding: '119px 90px 90px',
  textAlign: 'center',
  minHeight: 'calc(100vh - 62px)',
  height: 'calc(100% - 62px)',
  backgroundSize: 'cover',
});

const ProjectsContainer = styled.div({
  maxWidth: 1325,
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  ['@media (max-width: 1325px)']: {
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 118,
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '15px 28px 0',
});

const HeaderItemContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  width: 238,
  alignItems: 'center',
});

const Logo = styled.img({
  width: 238,
  height: 103,
});

const Wallet = styled.img({
  height: 40,
});

const CenterItem = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const Mountain = styled.img({
  height: 31,
});

const Heading = styled(H2V2)({
  fontSize: 36,
});

const SubHeader = styled(Subheading)({
  fontSize: 24,
  color: lightBlack,
  maxWidth: 501,
  textAlign: 'center',
  marginBottom: 52,
});

const NameText = styled(Subheading)({
  fontSize: 24,
  color: lightBlack,
  marginRight: 26,
});

const IconCircle = styled(Circle)({
  height: 44,
  width: 44,
  pointer: 'default',
});

const Icon = styled.img({
  height: '50%',
});

const LoadingContainer = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  paddingTop: 200,
});

const SprintButton = styled(Button)({
  width: '122px',
  height: '37px',
  flexGrow: 0,
  fontWeight: 600,
  position: 'absolute',
  right: '6em',
  top: '10em',
  borderRadius: '30px',
  backgroundColor: buttonGrey,
  '&:hover': {
    backgroundColor: lightBrown,
  },
});

const LargeSpinner = styled(Spinner)`
  & > div {
    width: 10px;
    height: 110px;
  }
`;
