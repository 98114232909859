import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import theme from 'shared/styles/theme';
import { primaryBlue, white } from 'shared/styles/colors';
import { CopySemiBold } from './Text';

const Copyright = () => {
  return (
    <CopySemiBold fontSize={theme.typography.pxToRem(12)}>
      {'Copyright © '}
      {new Date().getFullYear()}
      {'.'}
      {'All Rights Reserved.'}
    </CopySemiBold>
  );
};

const FooterV2 = () => {
  return (
    <FooterComponent>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={2} sm={4} md={4}>
          <Copyright />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignItems="center"
          columnSpacing={5.5}
          sm={4}
          md={4}
        >
          <Grid item>
            <TextLink to="/">Adrenaline Meeting Options</TextLink>
          </Grid>
          <Grid item>
            <TextLink to="/sprint-check-in">Daily Sprint Check-in</TextLink>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" item sm={4} md={4}>
          <CopySemiBold fontSize={theme.typography.pxToRem(12)}>
            Powered by Yeti
          </CopySemiBold>
        </Grid>
      </Grid>
    </FooterComponent>
  );
};

export default FooterV2;

const FooterComponent = styled.footer({
  padding: theme.spacing(2.5, 4, 3, 4.5),
  backgroundColor: '#6a5c28',
  color: white,
});

const TextLink = styled(Link)({
  color: white,
  textDecoration: 'underline',
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 600,
  margin: 0,
});
