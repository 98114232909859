import styled from '@emotion/styled';
import {
  secondaryBlue,
  white,
  tertiaryBlue,
  primaryBlue,
} from 'shared/styles/colors';
import theme from 'shared/styles/theme';
import { CopyLight, InterFontFamily } from '../Text';
import { motion, AnimatePresence } from 'framer-motion';
import { agendaCard, agendaLine } from 'shared/styles/animations';
import { Link } from 'react-router-dom';

interface AgendaCardProps {
  active?: boolean;
  content: {
    title: string;
    time: string;
  };
  type: string;
}

const AgendaCard = ({ active = false, content, type }: AgendaCardProps) => {
  return (
    <AnimatePresence>
      <CardLink to={`../${type}`} relative="path">
        <Card
          initial="initial"
          animate={active ? 'enter' : 'initial'}
          exit="initial"
          variants={agendaCard}
          style={{
            backgroundColor: active ? white : secondaryBlue,
            boxShadow: active ? theme.shadows[1] : 'none',
            fontWeight: active ? 600 : 400,
          }}
        >
          <Line
            style={{
              backgroundColor: active ? primaryBlue : tertiaryBlue,
            }}
            initial="initial"
            animate={active ? 'enter' : 'initial'}
            exit="initial"
            variants={agendaLine}
          />
          <CardTitle>{content.title}</CardTitle>
          <Time>{content.time}</Time>
        </Card>
      </CardLink>
    </AnimatePresence>
  );
};

export default AgendaCard;

const CardLink = styled(Link)({
  textDecoration: 'none',
});

const Card = styled(motion.div)({
  border: `1px solid ${tertiaryBlue}`,
  borderRadius: 10,
  position: 'relative',
  padding: theme.spacing(3.5, 5, 4, 4),
  color: primaryBlue,
});

const Line = styled(motion.div)({
  position: 'absolute',
  bottom: -1,
  content: "''",
  display: 'block',
  left: -1,
  width: 10,
  height: 'calc(100% + 2px)',
  borderRadius: 10,
});

const CardTitle = styled.p({
  fontWeight: 'inherit',
  fontSize: theme.typography.pxToRem(18),
  fontFamily: InterFontFamily,
});

const Time = styled(CopyLight)({});
