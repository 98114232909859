import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { primaryBlue, white, secondaryBlue } from 'shared/styles/colors';
import theme from 'shared/styles/theme';
import { buttonTransition } from 'shared/styles/transitions';
import { motion } from 'framer-motion';

export const ButtonStyle = css([
  {
    height: 56,
    maxWidth: 236,
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    outline: 'none',
    cursor: 'pointer',
    transition: buttonTransition,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    lineHeight: 1.21,
    textTransform: 'none',
  },
]);

export const ButtonPrimary = styled.button([
  ButtonStyle,
  {
    backgroundColor: primaryBlue,
    color: white,
    border: `1px solid ${primaryBlue}`,
    '&:hover': {
      color: primaryBlue,
      backgroundColor: white,
    },
  },
]);

export const ButtonSecondary = styled(motion.button)([
  ButtonStyle,
  {
    backgroundColor: secondaryBlue,
    color: primaryBlue,
    border: `1px solid ${primaryBlue}`,
  },
]);
