import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import theme from 'shared/styles/theme';
import { ButtonSecondary } from 'shared/components/Button';
import { Stack } from '@mui/material';
import { H1, Copy } from 'shared/components/Text';
import SplitLayout from 'shared/components/Layouts/SplitLayout';
import TransitionLayout from 'shared/components/Layouts/TransitionLayout';
import { ROUTES } from 'shared/constants';

const HighlightsIcebreakers = () => {
  const navigate = useNavigate();

  return (
    <TransitionLayout>
      <>
        <SplitLayout>
          <Stack alignItems="center" maxWidth={600}>
            <H1 lineHeight={1.21}>Highlights & Icebreaker</H1>
            <Text>
              Share how your weekend went and/or your <br /> answer to{' '}
              <TextLink
                target="_blank"
                href="https://www.notion.so/yeti-cave/Monday-Icebreakers-5019c7ac9bda46efb213a59281340d2c"
              >
                the question of the day
              </TextLink>
            </Text>
            <ButtonSecondary
              onClick={() =>
                navigate(ROUTES.HIGHLIGHTS_ICEBREAKERS.SPRINT_POINT_REMINDER)
              }
            >
              Continue
            </ButtonSecondary>
          </Stack>
        </SplitLayout>
      </>
    </TransitionLayout>
  );
};

export default HighlightsIcebreakers;

const Text = styled(Copy)({
  margin: theme.spacing(2, 0, 5.5),
});

const TextLink = styled.a({
  textDecoration: 'underline',
  fontWeight: 600,
  color: 'inherit',
});
