import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Modal as MuiModal, Fade } from '@mui/material';
import theme from 'shared/styles/theme';
import { CloseIcon } from 'shared/components/icons';
import { SerializedStyles } from '@emotion/react';

interface Props {
  children: ReactNode;
  handleOpen: boolean;
  handleClose: () => void;
  modalContentStyles?: SerializedStyles;
  hideCloseButton?: boolean;
  closeIconColor?: string;
}

export default function Modal({
  children,
  handleOpen,
  handleClose,
  modalContentStyles,
  hideCloseButton = false,
  closeIconColor,
}: Props) {
  return (
    <ModalComponent
      open={handleOpen}
      onClose={handleClose}
      hideBackdrop={false}
      closeAfterTransition
    >
      <Fade in={handleOpen}>
        <ModalContent modalContentStyles={modalContentStyles}>
          {!hideCloseButton && (
            <CloseButton onClick={handleClose}>
              <CloseIcon iconColor={closeIconColor} />
            </CloseButton>
          )}
          {children}
        </ModalContent>
      </Fade>
    </ModalComponent>
  );
}

const ModalComponent = styled(MuiModal)({
  display: 'flex',
  alignItems: 'center',
});

const ModalContent = styled.div(
  ({ modalContentStyles }: { modalContentStyles: any }) => [
    {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'fit-content',
      width: '60vw',
      margin: '0 auto',
      maxWidth: 905,
      minHeight: 589,
      borderRadius: 10,
      backgroundColor: theme.palette.secondary.main,
    },
    modalContentStyles,
  ]
);

const CloseButton = styled.button({
  position: 'absolute',
  top: 28,
  right: 26,
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  zIndex: theme.zIndex.modal,
});
