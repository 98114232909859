import { primaryBlue } from 'shared/styles/colors';

const BackIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path
        d="M10.5 22.5H40.5C40.8978 22.5 41.2794 22.658 41.5607 22.9393C41.842 23.2206 42 23.6022 42 24C42 24.3978 41.842 24.7794 41.5607 25.0607C41.2794 25.342 40.8978 25.5 40.5 25.5H10.5C10.1022 25.5 9.72064 25.342 9.43934 25.0607C9.15804 24.7794 9 24.3978 9 24C9 23.6022 9.15804 23.2206 9.43934 22.9393C9.72064 22.658 10.1022 22.5 10.5 22.5Z"
        fill={primaryBlue}
      />
      <path
        d="M11.1208 23.9999L23.5618 36.4379C23.8434 36.7196 24.0016 37.1016 24.0016 37.4999C24.0016 37.8983 23.8434 38.2803 23.5618 38.5619C23.2801 38.8436 22.8981 39.0018 22.4998 39.0018C22.1014 39.0018 21.7194 38.8436 21.4377 38.5619L7.93775 25.0619C7.79806 24.9226 7.68723 24.7571 7.61161 24.5748C7.53599 24.3926 7.49707 24.1972 7.49707 23.9999C7.49707 23.8026 7.53599 23.6073 7.61161 23.425C7.68723 23.2428 7.79806 23.0773 7.93775 22.9379L21.4377 9.43794C21.7194 9.15628 22.1014 8.99805 22.4998 8.99805C22.8981 8.99805 23.2801 9.15628 23.5618 9.43794C23.8434 9.7196 24.0016 10.1016 24.0016 10.4999C24.0016 10.8983 23.8434 11.2803 23.5618 11.5619L11.1208 23.9999Z"
        fill={primaryBlue}
      />
    </svg>
  );
};

export default BackIcon;
