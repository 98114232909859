import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { routes } from './routes';
import { Global } from '@emotion/react';
import { globalStyles } from 'shared/styles/globalStyles';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from 'shared/styles/theme';
import { AnimatePresence } from 'framer-motion';
import 'shared/styles/fonts.css';
import { LiveEventsProvider } from 'context/liveEvents';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const routeElements = routes.map(({ path, element }, index) => {
    return <Route key={`${path}-${index}`} path={path} element={element} />;
  });

  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      <AnimatePresence exitBeforeEnter={false} initial={false}>
        <LiveEventsProvider>
          <Routes location={location} key={location.pathname}>
            {routeElements}
          </Routes>
        </LiveEventsProvider>
      </AnimatePresence>
      <CssBaseline />
    </ThemeProvider>
  );
}

export default App;
