import { format, differenceInDays, parseISO } from 'date-fns';
import { DATE_REGEX } from 'shared/constants';

export const toPascalCase = (text: string) => {
  return text.replace(/(^\w|-\w)/g, clearAndUpper);
};

export const clearAndUpper = (text: string) => {
  return text.replace(/-/, '').toUpperCase();
};

export const formatDate = (date: Date | string) => {
  if (typeof date === 'string') {
    if (!DATE_REGEX.test(date)) {
      throw Error(`Date is not correctly formatted. Received: ${date}`);
    }

    return format(parseISO(date), 'iiii, MMMM dd, yyyy');
  }

  return format(date, 'iiii, MMMM dd, yyyy');
};

export const getDayFromDate = (date: Date | string) => {
  if (typeof date === 'string') {
    if (!DATE_REGEX.test(date)) {
      throw Error(`Date is not correctly formatted. Received: ${date}`);
    }

    return format(parseISO(date), 'EEEE');
  }

  return format(date, 'EEEE');
};

export const isDateWithinTwoWeeks = (date: Date | string) => {
  const currentDate = new Date();

  if (typeof date === 'string') {
    if (!DATE_REGEX.test(date)) {
      throw Error(`Date is not correctly formatted. Received: ${date}`);
    }

    return differenceInDays(currentDate, parseISO(date)) <= 14;
  }

  return differenceInDays(currentDate, date) <= 14;
};
