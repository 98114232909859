import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import theme from 'shared/styles/theme';
import { ButtonPrimary } from 'shared/components/Button';
import { Stack } from '@mui/material';
import { H1, Copy } from 'shared/components/Text';
import SplitLayout from 'shared/components/Layouts/SplitLayout';
import TransitionLayout from 'shared/components/Layouts/TransitionLayout';

const Cheer = () => {
  const navigate = useNavigate();

  return (
    <TransitionLayout>
      <>
        <SplitLayout>
          <Stack alignItems="center" maxWidth={550}>
            <H1 lineHeight={1.21}>Cheer of the day!</H1>
            <Text>What will it be? 1...2....3 Cheers!</Text>
            <ButtonPrimary
              onClick={() =>
                navigate('/', { state: { useSlideTransition: true } })
              }
            >
              End Meeting
            </ButtonPrimary>
          </Stack>
        </SplitLayout>
      </>
    </TransitionLayout>
  );
};

export default Cheer;

const Text = styled(Copy)({
  margin: theme.spacing(2, 0, 6.5),
});
