import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_MOUSE_POSITION } from 'shared/mutations/mutations.liveEvents';

export interface UpdateMouseArgs {
  userName: string;
  color: string;
  icon: string;
  setLatestMousePosition: Dispatch<SetStateAction<any>>;
  setMouseHasMoved: Dispatch<SetStateAction<any>>;
}

// copied from https://www.joshwcomeau.com/snippets/react-hooks/use-mouse-position/
const useUpdateMousePosition = ({
  userName,
  color,
  icon,
  setLatestMousePosition,
  setMouseHasMoved,
}: UpdateMouseArgs) => {
  const time = useRef(Date.now());
  const [updateMousePosition] = useMutation(UPDATE_MOUSE_POSITION);
  useEffect(() => {
    const handleMouseEvent = (event: MouseEvent) => {
      if (Date.now() > time.current + 50) {
        setMouseHasMoved(true);
        const xPosition = Number(
          (event.pageX - window.innerWidth / 2).toFixed(2)
        );
        const yPosition = Number(event.pageY.toFixed(2));

        setLatestMousePosition({
          pageX: xPosition,
          pageY: yPosition,
        });

        updateMousePosition({
          variables: {
            userName: userName,
            color: color,
            icon: icon,
            pageX: xPosition,
            pageY: yPosition,
          },
        });
      }
    };
    window.addEventListener('mousemove', handleMouseEvent);
    return () => {
      window.removeEventListener('mousemove', handleMouseEvent);
    };
  }, [userName, color, icon]);
};
export default useUpdateMousePosition;
