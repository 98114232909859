import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import AgendaCard from 'shared/components/Agenda/AgendaCard';
import { secondaryBlue } from 'shared/styles/colors';
import theme from 'shared/styles/theme';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import * as agendaContent from 'shared/content/agenda';
import { toPascalCase } from 'shared/utils/utils';
import { MontserratSemiBold } from 'shared/components/Text';
import { useLocation } from 'react-router-dom';

interface Agenda {
  [key: string]: { title: string; time: string; type: string }[];
}

const Agenda = () => {
  const agenda: Agenda = agendaContent;
  const [adrenalineType] = useLocalStorage({
    key: 'adrenalineType',
  });

  const [agendaCards, setAgendaCards] = useState<JSX.Element[]>();
  const { pathname } = useLocation();

  useEffect(() => {
    const pascaleAdrenalineType = toPascalCase(adrenalineType);
    const nestedPath = pathname.split('/')[2];

    const agendaCards = agenda[pascaleAdrenalineType].map((card) => {
      return (
        <AgendaCard
          key={card.title}
          active={nestedPath.includes(card.type)}
          content={{ title: card.title, time: card.time }}
          type={card.type}
        ></AgendaCard>
      );
    });

    setAgendaCards(agendaCards);
  }, []);

  return (
    <Container>
      <Heading>Meeting Agenda:</Heading>
      <Stack spacing={2}>{agendaCards}</Stack>
    </Container>
  );
};

export default Agenda;

const Container = styled.div({
  backgroundColor: secondaryBlue,
  borderRadius: 36,
  padding: theme.spacing(3.75, 3, 4.75, 3),
});

const Heading = styled.h2([
  MontserratSemiBold,
  {
    fontSize: theme.typography.pxToRem(15),
    marginBottom: theme.spacing(2),
  },
]);
