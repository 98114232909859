import styled from '@emotion/styled';
import theme from 'shared/styles/theme';
import { Grid } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import Agenda from 'shared/components/Agenda/Agenda';
import { contentFade } from 'shared/styles/animations';

interface Props {
  children: React.ReactNode;
}

export default function SplitLayout({ children }: Props) {
  return (
    <ContainerGrid container alignItems="stretch" columns={12}>
      <LeftGridItem container alignItems="center" gridRow={12} item xs={5}>
        <LeftContainer>
          <Agenda />
        </LeftContainer>
      </LeftGridItem>
      <RightGridItem
        container
        item
        xs={7}
        gridRow={12}
        alignItems="center"
        justifyContent="center"
      >
        <AnimatePresence>
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={contentFade}
          >
            {children}
          </motion.div>
        </AnimatePresence>
      </RightGridItem>
    </ContainerGrid>
  );
}
const ContainerGrid = styled(Grid)({
  flex: 1,
  flexWrap: 'nowrap',
});

const LeftGridItem = styled(Grid)({});

const RightGridItem = styled(Grid)({
  textAlign: 'center',
});

const LeftContainer = styled.div({
  padding: theme.spacing(0, 8, 0, 9.5),
});
