import { useLocation } from 'react-router-dom';
import { IntroHeadline } from 'shared/components/Text';
import IntroPageGroup from 'shared/components/IntroPageGroup';
import TransitionLayout from 'shared/components/Layouts/TransitionLayout';
import HighlightsGif from 'shared/static/images/highlights.gif';

const HightlightsIcebreakersIntro = () => {
  const { pathname } = useLocation();

  return (
    <TransitionLayout>
      <IntroPageGroup
        imagePath={HighlightsGif}
        path={`${pathname}/weekend-updates`}
        headline={<IntroHeadline>Highlights & Icebreakers</IntroHeadline>}
      />
    </TransitionLayout>
  );
};

export default HightlightsIcebreakersIntro;
