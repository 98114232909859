export const ROUTES = {
  HOME: '/',
  SPRINT_CHECK_IN: 'sprint-check-in',
  HIGHLIGHTS_ICEBREAKERS: {
    ROOT: '/highlights-icebreakers',
    WEEKEND_UPDATES: '/highlights-icebreakers/weekend-updates',
    SPRINT_POINT_REMINDER: '/highlights-icebreakers/sprint-point-reminder',
    STREAK_WINNERS: '/highlights-icebreakers/streak-winners',
    SHOUTOUTS: '/highlights-icebreakers/shoutouts',
    CHEER: '/highlights-icebreakers/cheer',
  },
  TEAM_UPDATE: {
    ROOT: '/team-update',
    BREAKOUT_ROOMS: '/team-update/breakout-rooms',
    SHOUTOUTS: '/team-update/shoutouts',
    SPRINT_POINT_REMINDER: '/team-update/sprint-point-reminder',
    PROJECT_UPDATE: '/team-update/project-update',
    CHEER: '/team-update/cheer',
  },
  FUN_ACTIVITY: {
    ROOT: '/fun-activity',
    BREAKOUT_ROOMS: '/fun-activity/breakout-rooms',
    SHOUTOUTS: '/fun-activity/shoutouts',
    SPRINT_POINT_REMINDER: '/fun-activity/sprint-point-reminder',
    ACTIVITY: '/fun-activity/activity',
    CHEER: '/fun-activity/cheer',
  },
  PARTY_PARROT: {
    ROOT: '/party-parrot',
    BREAKOUT_ROOMS: '/party-parrot/breakout-rooms',
    SHOUTOUTS: '/party-parrot/shoutouts',
    SPRINT_POINT_REMINDER: '/party-parrot/sprint-point-reminder',
    PARTY_PARROTS: '/party-parrot/party-parrots',
    CHEER: '/party-parrot/cheer',
  },
};

export const NUMBER_REGEX = /^\d+$/;
// RegExp to test a string for a full ISO 8601 Date
// Source: https://stackoverflow.com/a/26972181
export const DATE_REGEX =
  /^(?:(?:1[6-9]|[2-9]\d)?\d{2})(?:(?:(\/|-|\.)(?:0?[13578]|1[02])\1(?:31))|(?:(\/|-|\.)(?:0?[13-9]|1[0-2])\2(?:29|30)))$|^(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(\/|-|\.)0?2\3(?:29)$|^(?:(?:1[6-9]|[2-9]\d)?\d{2})(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:0?[1-9]|1\d|2[0-8])$/;
