import { gql } from '@apollo/client';

export const PARTY_PARROTS = gql`
  query PartyParrots {
    partyParrots {
      projectName
      streakNumber
    }
  }
`;
