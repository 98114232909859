import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { Subheading } from 'shared/components/Text';
import {
  lightBlack,
  progressBarBeige,
  progressBarGreen,
  progressBarYellow,
} from 'shared/styles/colors';

interface Props {
  pointsCompleted: number;
  goal: number;
  setPointsCompleted: React.Dispatch<React.SetStateAction<number>>;
  handleUpdatePoints: ({
    goal,
    completedPoints,
  }: {
    goal: string | number;
    completedPoints: string | number;
  }) => void;
}

const width = 584;

const UpdatePointsBar = ({
  pointsCompleted,
  goal,
  setPointsCompleted,
  handleUpdatePoints,
}: Props) => {
  const progressBarContainerRef = useRef<HTMLDivElement>(null);
  const isSprintComplete = pointsCompleted >= goal && goal !== 0;
  const [progressStart, setProgressStart] = useState(0);
  const [progressWidth, setProgressWidth] = useState(
    goal === 0 ? 0 : isSprintComplete ? width : (pointsCompleted / goal) * width
  );

  const handleMouseMove = (e: MouseEvent) => {
    const progressMoved = e.pageX - progressStart;
    if (progressMoved > width) {
      setProgressWidth(width);
      setPointsCompleted(goal);
    } else if (progressMoved <= 0) {
      setProgressWidth(0);
      setPointsCompleted(0);
    } else {
      setProgressWidth(progressMoved);
      setPointsCompleted(Math.floor((progressMoved / width) * goal));
    }
  };

  const handleMouseUp = (e: MouseEvent) => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    const progressMoved = e.pageX - progressStart;
    if (progressMoved > width) {
      setProgressWidth(width);
      handleUpdatePoints({ goal, completedPoints: goal });
    } else if (progressMoved <= 0) {
      setProgressWidth(0);
      handleUpdatePoints({ goal, completedPoints: 0 });
    } else {
      handleUpdatePoints({
        goal,
        completedPoints: Math.floor((progressMoved / width) * goal),
      });
    }
  };

  const handleMouseDown = () => {
    if (goal === 0) {
      return;
    }
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
  };

  useEffect(() => {
    if (progressBarContainerRef.current) {
      const xOffset = progressBarContainerRef.current.getBoundingClientRect().x;
      setProgressStart(xOffset);
    }
  }, [progressBarContainerRef]);

  return (
    <ProgressBarContainer
      ref={progressBarContainerRef}
      onMouseDown={handleMouseDown}
    >
      <ProgressBarFill
        animate={{ width: progressWidth }}
        transition={{ duration: 0 }}
        isFilled={isSprintComplete}
        width={isSprintComplete ? 100 : (pointsCompleted / goal) * 100}
      >
        <ArrowContainer>
          <Arrow isLeft={true} />
          <Arrow isLeft={false} />
        </ArrowContainer>
      </ProgressBarFill>
      {goal === 0 && <Copy>(enter your sprint goal first)</Copy>}
    </ProgressBarContainer>
  );
};

export default UpdatePointsBar;

const ProgressBarContainer = styled.div({
  display: 'flex',
  height: 53,
  width: width,
  backgroundColor: progressBarBeige,
  bottom: 0,
  overflow: 'hidden',
});

const ProgressBarFill = styled(motion.div, {
  shouldForwardProp: (props) => props !== 'isFilled',
})(({ width, isFilled }: { width: number; isFilled: boolean }) => ({
  width: `${width}%`,
  position: 'relative',
  background: isFilled ? progressBarYellow : progressBarGreen,
  height: '100%',
  transition: '300ms linear',
  borderRight: '3px solid black',
}));

const Copy = styled(Subheading)({
  width: '100%',
  alignSelf: 'center',
  textAlign: 'center',
  fontSize: 20,
  color: lightBlack,
  marginRight: 4,
});

const ArrowContainer = styled.div({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  width: 41,
  right: -21,
});

const Arrow = styled.div(({ isLeft }: { isLeft?: boolean }) => ({
  transform: `rotate(${isLeft ? 270 : 90}deg)`,
  width: 5,
  height: 5,
  borderLeft: '6px solid transparent',
  borderRight: '6px solid transparent',
  borderBottom: '10px solid black',
}));
