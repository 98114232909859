import type { ReactElement } from 'react';
import styled from '@emotion/styled';
import Footer from 'shared/components/Footer';
import { white } from 'shared/styles/colors';
import { motion } from 'framer-motion';
import { YetiLogo } from 'shared/components/icons';
interface Props {
  children: ReactElement;
}

export default function MainLayout({ children }: Props) {
  return (
    <LayoutComponent>
      <MainComponent>
        <LogoContainer>
          <YetiLogo height={67} width={167} />
        </LogoContainer>
        {children}
      </MainComponent>
      <Footer />
    </LayoutComponent>
  );
}

const MainComponent = styled.main<{ backgroundColor?: string }>(
  ({ backgroundColor = white }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flex: 1,
    backgroundColor: backgroundColor,
    minHeight: 'calc(100vh - 62px)',
    overflow: 'hidden',
  })
);

const LayoutComponent = styled(motion.div)({
  minHeight: 'calc(100vh - 62px)',
  width: '100vw',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  justifyContent: 'center',
});

const LogoContainer = styled.div({
  position: 'absolute',
  top: 29,
  right: 72,
});
