import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import theme from 'shared/styles/theme';
import { ButtonSecondary } from 'shared/components/Button';
import { Stack } from '@mui/material';
import { H1, Copy } from 'shared/components/Text';
import SplitLayout from 'shared/components/Layouts/SplitLayout';
import MainLayout from 'shared/components/Layouts/Main';

interface ShoutoutsProps {
  path: string;
}

const Shoutouts = ({ path }: ShoutoutsProps) => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <>
        <SplitLayout>
          <Stack alignItems="center" maxWidth={550}>
            <H1 lineHeight={1.21}>
              Yeti Shoutouts <br /> & Updates
            </H1>
            <Text>
              Have a shoutout for a yeti? Project or life updates? News? Tell us
              about it.
            </Text>
            <ButtonSecondary onClick={() => navigate(path)}>
              Continue
            </ButtonSecondary>
          </Stack>
        </SplitLayout>
      </>
    </MainLayout>
  );
};

export default Shoutouts;

const Text = styled(Copy)({
  margin: theme.spacing(2, 0, 6.5),
});
