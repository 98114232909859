import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { secondaryBlue } from 'shared/styles/colors';
import { Stack } from '@mui/material';
import { ButtonPrimary } from 'shared/components/Button';
import { ReactNode } from 'react';
import { BackIcon } from 'shared/components/icons';

interface IntroPageGroupProps {
  imagePath?: string;
  headline?: ReactNode;
  path?: string;
}

const IntroPageGroup = ({ headline, path, imagePath }: IntroPageGroupProps) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`${path}`);
  };
  return (
    <>
      <IntroPageContainer justifyContent="center" alignItems="center">
        <BackButton onClick={() => navigate('/')}>
          <BackIcon />
        </BackButton>
        <img src={imagePath} />
        {headline}
        <ButtonPrimary onClick={handleNavigate}>Start Adrenaline</ButtonPrimary>
      </IntroPageContainer>
    </>
  );
};

export default IntroPageGroup;

const IntroPageContainer = styled(Stack)({
  backgroundColor: secondaryBlue,
  height: '100%',
  flexGrow: 1,
  textAlign: 'center',
});

const BackButton = styled.button({
  position: 'absolute',
  top: 68,
  left: 48,
  background: 'none',
  border: 'none',
  cursor: 'pointer',
});
