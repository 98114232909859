import Spinner from 'react-spinkit';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import theme from 'shared/styles/theme';
import { ButtonSecondary } from 'shared/components/Button';
import { Stack } from '@mui/material';
import { H1, Copy } from 'shared/components/Text';
import SplitLayout from 'shared/components/Layouts/SplitLayout';
import MainLayout from 'shared/components/Layouts/Main';
import { useEffect, useState } from 'react';
import StreakWinnersModal from 'shared/components/StreakWinners/StreakWinnersModal';
import { STREAKS } from './query.streaks';
import { useQuery } from '@apollo/client';
import { primaryBlue } from 'shared/styles/colors';
import ReactConfetti from 'react-confetti';

interface StreakWinnersProps {
  path: string;
}

const StreakWinners = ({ path }: StreakWinnersProps) => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(STREAKS, {
    fetchPolicy: 'no-cache',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (data) {
      setIsModalOpen(true);
    }
  }, [data]);

  const buttonContent = loading ? (
    <Spinner name="pacman" color={primaryBlue} />
  ) : (
    'continue'
  );

  return (
    <MainLayout>
      <>
        <SplitLayout>
          <ReactConfetti />
          {data && (
            <StreakWinnersModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              streaks={data?.streaks}
              onContinueClick={() => navigate(path)}
            />
          )}
          <Stack alignItems="center" maxWidth={550}>
            <H1 lineHeight={1.21}>Streak Winners</H1>
            <Text>
              Go winners! Continue on track for more chances to get your desired
              party parrot
            </Text>
            <ButtonSecondary
              onClick={() => {
                setIsModalOpen(false);
                navigate(path);
              }}
            >
              {buttonContent}
            </ButtonSecondary>
          </Stack>
        </SplitLayout>
      </>
    </MainLayout>
  );
};

export default StreakWinners;

const Text = styled(Copy)({
  margin: theme.spacing(2, 0, 6.5),
});
