import { useSubscription } from '@apollo/client';
import styled from '@emotion/styled';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SOCIAL_CURSOR } from './subscriptions.liveEvents';
import { motion } from 'framer-motion';
import { Copy } from 'shared/components/Text';
import { black } from 'shared/styles/colors';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import { MousePosition } from 'shared/types/typedDocumentNodes';

export interface LiveEventsContent {
  setUserToFilter: (userName: string) => void;
}

export const LiveEventsContext = createContext<LiveEventsContent>({
  setUserToFilter: (_userName: string) => {},
});

export const useLiveEventsContext = () => useContext(LiveEventsContext);

export const LiveEventsProvider = ({ children }: { children: ReactNode }) => {
  const [userName, setUserName] = useState('');
  const [liveMousePositions, setLiveMousePositions] = useState<
    Record<string, MousePosition>
  >({});
  const { pathname } = useLocation();
  const shouldShowLiveEvents = pathname.slice(1) === ROUTES.SPRINT_CHECK_IN;

  useSubscription(SOCIAL_CURSOR, {
    onData: ({ data }) => {
      if (data.data.liveMouseTracking) {
        setLiveMousePositions({
          ...liveMousePositions,
          [data.data.liveMouseTracking.userName]: data.data.liveMouseTracking,
        });
      }
    },
  });

  const setUserToFilter = (userName: string) => {
    setUserName(userName);
  };

  return (
    <LiveEventsContext.Provider value={{ setUserToFilter }}>
      {shouldShowLiveEvents &&
        Object.keys(liveMousePositions)
          .filter(
            (key) =>
              key !== userName &&
              liveMousePositions[key].userName &&
              liveMousePositions[key].color &&
              liveMousePositions[key].icon
          )
          .map((key) => (
            <CursorContainer
              key={key}
              animate={{
                x: liveMousePositions[key].pageX! + window.innerWidth / 2,
                y: liveMousePositions[key].pageY!,
              }}
              transition={{ duration: 0.1 }}
            >
              <Cursor color={liveMousePositions[key].color!}>
                <Icon src={liveMousePositions[key].icon!} />
              </Cursor>
              <NameTag>{liveMousePositions[key].userName!}</NameTag>
            </CursorContainer>
          ))}
      {children}
    </LiveEventsContext.Provider>
  );
};

const CursorContainer = styled(motion.div)({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 32,
  zIndex: 10,
});

const Cursor = styled.div(({ color }: { color: string }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 31,
  width: 31,
  borderRadius: 20,
  border: '1px solid black',
  backgroundColor: color,
}));

const Icon = styled.img({
  height: 22,
  width: 22,
  objectFit: 'contain',
});

const NameTag = styled(Copy)({
  marginLeft: 2,
  marginTop: 2,
  fontSize: 12,
  color: black,
});
