import styled from '@emotion/styled';

interface Props {
  height: number;
  width: number;
}

export const Spacer = styled.div(({ height, width }: Props) => ({
  height,
  width,
}));
