import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import theme from 'shared/styles/theme';
import { ButtonSecondary } from 'shared/components/Button';
import { Stack } from '@mui/material';
import { H1, Copy } from 'shared/components/Text';
import SplitLayout from 'shared/components/Layouts/SplitLayout';
import MainLayout from 'shared/components/Layouts/Main';
import { ROUTES } from 'shared/constants';

const FunActivity = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <>
        <SplitLayout>
          <Stack alignItems="center" maxWidth={600}>
            <H1 lineHeight={1.21}>Fun Activity</H1>
            <Text>
              Let's end the week with some fun! The host will provide further
              instructions.{' '}
              <TextLink
                target="_blank"
                href="https://www.notion.so/yeti-cave/Fun-Friday-Activities-5797d53cf98a497e9c775079c30856a4"
              >
                View Activites
              </TextLink>
            </Text>
            <ButtonSecondary
              onClick={() => navigate(ROUTES.FUN_ACTIVITY.CHEER)}
            >
              Continue
            </ButtonSecondary>
          </Stack>
        </SplitLayout>
      </>
    </MainLayout>
  );
};

export default FunActivity;

const Text = styled(Copy)({
  margin: theme.spacing(2, 0, 6),
});

const TextLink = styled.a({
  textDecoration: 'underline',
  fontWeight: 600,
  color: 'inherit',
});
