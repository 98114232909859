import {
  lightRed,
  orange,
  yellow,
  limeGreen,
  mintGreen,
  lightBlue,
  blue,
  purple,
  pink,
  red,
} from 'shared/styles/colors';

import Paw from 'shared/static/images/Paw.png';
import Book from 'shared/static/images/Book.png';
import Plane from 'shared/static/images/Plane.png';
import Boat from 'shared/static/images/Boat.png';
import Rose from 'shared/static/images/Rose.png';
import Lobster from 'shared/static/images/Wolf.png';
import Coffee from 'shared/static/images/Coffee.png';
import Camera from 'shared/static/images/Camera.png';
import Scooter from 'shared/static/images/Scooter.png';
import Apple from 'shared/static/images/Apple.png';
import Boot from 'shared/static/images/Boot.png';
import Wolf from 'shared/static/images/Wolf.png';
import Rooster from 'shared/static/images/Rooster.png';
import Cat from 'shared/static/images/Cat.png';
import Van from 'shared/static/images/Van.png';
import Board from 'shared/static/images/Board.png';

export const iconArray = [
  Paw,
  Book,
  Plane,
  Boat,
  Rose,
  Lobster,
  Coffee,
  Camera,
  Scooter,
  Apple,
  Boot,
  Wolf,
  Rooster,
  Cat,
  Van,
  Board,
];

export const colorArray = [
  lightRed,
  orange,
  yellow,
  limeGreen,
  mintGreen,
  lightBlue,
  blue,
  purple,
  pink,
  red,
];
