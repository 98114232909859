import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import theme from 'shared/styles/theme';
import { Grid, Stack, Container } from '@mui/material';
import { secondaryBlue, white, primaryBlue } from 'shared/styles/colors';
import {
  H1Alt,
  Subheading,
  CopyBold,
  MontserratSemiBold,
} from 'shared/components/Text';
import { YetiLogo } from 'shared/components/icons';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { cardTransition } from 'shared/styles/transitions';
import TransitionLayout from 'shared/components/Layouts/TransitionLayout';

interface LocationState {
  useSlideTransition?: boolean;
}

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as LocationState;

  const [useSlideTransition, setUseSlideTransition] = useState(false);
  const [adrenalineTypeActive, setAdrenalineTypeActive] = useState<
    string | null
  >('');
  const [_adrenalineType, setAdrenalineType] = useLocalStorage({
    key: 'adrenalineType',
  });

  useEffect(() => {
    if (locationState && locationState.useSlideTransition) {
      setUseSlideTransition(true);
    }
    navigate(location.pathname, { replace: true });
  }, []);

  const handleNavigate = (adrenalineType: string) => {
    setAdrenalineType(adrenalineType);
    setAdrenalineTypeActive(adrenalineType);
    navigate(`${adrenalineType}`);
  };

  return (
    <TransitionLayout useSlideTransition={useSlideTransition}>
      <>
        <ContainerGrid container alignItems="stretch" columns={15}>
          <LeftGridItem
            container
            item
            xs={7}
            gridRow={12}
            alignItems="center"
            justifyContent="center"
          >
            <Stack justifyContent="center" alignItems="center">
              <LogoContainer>
                <YetiLogo />
              </LogoContainer>
              <H1Alt lineHeight={1.21}>
                Welcome to <br /> Adrenaline
              </H1Alt>
              <Subhead>
                Regular host? Welcome back! <br /> First time hosting? You got
                this!
              </Subhead>
            </Stack>
          </LeftGridItem>
          <RightGridItem
            container
            justifyContent="center"
            alignItems="center"
            gridRow={12}
            item
            xs={8}
          >
            <Container>
              <AdrenalineTypeCopy>
                Select today's adrenaline style:
              </AdrenalineTypeCopy>
              <Grid
                item
                container
                spacing={5}
                justifyContent="center"
                gridAutoRows={1}
              >
                <Grid item alignItems="stretch" xs={5}>
                  <AdrenalineType
                    onClick={() => handleNavigate('highlights-icebreakers')}
                    active={adrenalineTypeActive === 'highlights-icebreakers'}
                  >
                    <CopyBold>
                      Highlights/
                      <br />
                      Icebreakers
                    </CopyBold>
                  </AdrenalineType>
                </Grid>
                <Grid item xs={5}>
                  <AdrenalineType
                    active={adrenalineTypeActive === 'team-update'}
                    onClick={() => handleNavigate('team-update')}
                  >
                    <CopyBold>
                      Team <br /> Update
                    </CopyBold>
                  </AdrenalineType>
                </Grid>
                <Grid item xs={5}>
                  <AdrenalineType
                    active={adrenalineTypeActive === 'fun-activity'}
                    onClick={() => handleNavigate('fun-activity')}
                  >
                    <CopyBold>Fun Activity</CopyBold>
                  </AdrenalineType>
                </Grid>
                <Grid item xs={5}>
                  <AdrenalineType
                    active={adrenalineTypeActive === 'party-parrot'}
                    onClick={() => handleNavigate('party-parrot')}
                  >
                    <CopyBold>Party Parrot</CopyBold>
                  </AdrenalineType>
                </Grid>
              </Grid>
            </Container>
          </RightGridItem>
        </ContainerGrid>
      </>
    </TransitionLayout>
  );
};

export default Home;

const ContainerGrid = styled(Grid)({
  flex: 1,
  flexWrap: 'nowrap',
});

const LogoContainer = styled.div({});

const LeftGridItem = styled(Grid)({
  textAlign: 'center',
  padding: theme.spacing(0, 8, 0, 8),
});

const RightGridItem = styled(Grid)({
  padding: theme.spacing(0, 12, 0, 8),
  backgroundColor: secondaryBlue,
});

const Subhead = styled(Subheading)({
  margin: theme.spacing(2, 'auto', 3),
});

const AdrenalineTypeCopy = styled.h2([
  MontserratSemiBold,
  {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(25),
    marginBottom: theme.spacing(5.5),
  },
]);

const AdrenalineType = styled.div<{ active?: boolean }>(({ active }) => ({
  backgroundColor: active ? primaryBlue : white,
  border: `2px solid ${primaryBlue}`,
  borderLeft: `2px solid ${primaryBlue}`,
  borderRadius: 29,
  color: active ? white : primaryBlue,
  padding: theme.spacing(8, 3),
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 231,
  cursor: 'pointer',
  transition: cardTransition,
  '&:hover': {
    color: white,
    backgroundColor: primaryBlue,
  },
}));
