const ProfileIcon = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4805 9.2736C11.5882 8.55528 12.3298 7.39223 12.5141 6.08498C12.6983 4.77792 12.3069 3.45508 11.4411 2.45883C10.5752 1.46234 9.31992 0.890121 7.99994 0.890121C6.67997 0.890121 5.42469 1.46234 4.55876 2.45883C3.69302 3.45513 3.30158 4.77792 3.48581 6.08498C3.67005 7.39223 4.41168 8.55528 5.51935 9.2736C4.14226 9.72535 2.94206 10.5989 2.0888 11.7705C1.2356 12.9421 0.772274 14.3521 0.764648 15.8015V19.392H15.235V15.8341C15.2343 14.3793 14.7742 12.9618 13.9204 11.7837C13.0666 10.6057 11.8628 9.72721 10.4804 9.27355L10.4805 9.2736ZM4.52908 5.45467C4.52908 4.5341 4.89488 3.65146 5.54568 3.0005C6.19663 2.34955 7.07927 1.9839 7.99985 1.9839C8.92042 1.9839 9.80306 2.34951 10.454 3.0005C11.1048 3.65146 11.4706 4.5341 11.4706 5.45467C11.4706 6.37505 11.1048 7.25788 10.454 7.90884C9.80306 8.5596 8.92042 8.92544 7.99985 8.92544C7.0802 8.92259 6.19911 8.55584 5.54889 7.90563C4.89866 7.25541 4.5319 6.37432 4.52908 5.45467ZM14.1472 18.3038H1.85279V15.834C1.85564 14.2939 2.46868 12.8178 3.55755 11.7289C4.64662 10.6401 6.12271 10.027 7.66262 10.024H8.33732C9.87718 10.027 11.3533 10.6401 12.4424 11.7289C13.5313 12.8178 14.1443 14.2939 14.1471 15.834L14.1472 18.3038Z"
        fill="black"
      />
    </svg>
  );
};

export default ProfileIcon;
