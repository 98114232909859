import Cookies from 'js-cookie';

export enum Identifiers {
  UserName = 'UserName',
  UserIcon = 'UserIcon',
  UserColor = 'UserColor',
}

export default class CookiesService {
  public static getCookie(key: Identifiers): string | undefined {
    return Cookies.get(key);
  }

  public static setCookie(key: Identifiers, value: string): void {
    const isSecure = process.env.REACT_APP_IS_SECURE === 'true' ? true : false;
    Cookies.set(key, value, { secure: isSecure });
  }

  public static removeCookie(key: Identifiers): void {
    Cookies.remove(key);
  }
}
