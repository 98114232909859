import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TextStyle } from 'shared/types';
import theme from 'shared/styles/theme';
import { lightBlack } from 'shared/styles/colors';

export const InterFontFamily = 'Inter, sans-serif';
export const Montserrat = 'Montserrat, sans-serif';

export const Aviator = 'Aviator Sans Vintage, sans-serif';

export const InterLight = css({
  fontFamily: InterFontFamily,
  fontWeight: 300,
});

export const InterRegular = css({
  fontFamily: InterFontFamily,
  fontWeight: 400,
});

export const InterSemibold = css({
  fontFamily: InterFontFamily,
  fontWeight: 600,
});

export const InterBold = css({
  fontFamily: InterFontFamily,
  fontWeight: 700,
});

export const MontserratRegular = css({
  fontFamily: Montserrat,
  fontWeight: 400,
});

export const MontserratMedium = css({
  fontFamily: Montserrat,
  fontWeight: 500,
});

export const MontserratSemiBold = css({
  fontFamily: Montserrat,
  fontWeight: 600,
});

export const MontserratBold = css({
  fontFamily: Montserrat,
  fontWeight: 700,
});

export const AviatorRegular = css({
  fontFamily: Aviator,
  fontWeight: 400,
});

export const H1 = styled.h1(({ fontSize, lineHeight }: TextStyle) => [
  MontserratMedium,
  {
    fontSize: fontSize ?? theme.typography.pxToRem(60),
    lineHeight: lineHeight ?? 1.2,
  },
]);

export const H1Alt = styled.h1(({ fontSize, lineHeight }: TextStyle) => [
  MontserratSemiBold,
  {
    fontSize: fontSize ?? theme.typography.pxToRem(60),
    lineHeight: lineHeight ?? 1.2,
  },
]);

export const Subheading = styled.p(({ fontSize, lineHeight }: TextStyle) => [
  MontserratRegular,
  {
    fontSize: fontSize ?? theme.typography.pxToRem(30),
    lineHeight: lineHeight ?? 1.2,
  },
]);

export const CopyLight = styled.p(({ fontSize, lineHeight }: TextStyle) => [
  InterLight,
  {
    fontSize: fontSize ?? theme.typography.pxToRem(15),
    lineHeight: lineHeight ?? 1.2,
  },
]);

export const Copy = styled.p(({ fontSize, lineHeight }: TextStyle) => [
  InterRegular,
  {
    fontSize: fontSize ?? theme.typography.pxToRem(25),
    lineHeight: lineHeight ?? 1.2,
  },
]);

export const CopySemiBold = styled.p(({ fontSize, lineHeight }: TextStyle) => [
  InterSemibold,
  {
    fontSize: fontSize ?? theme.typography.pxToRem(20),
    lineHeight: lineHeight ?? 1.2,
  },
]);

export const CopyBold = styled.p(({ fontSize, lineHeight }: TextStyle) => [
  InterBold,
  {
    fontSize: fontSize ?? theme.typography.pxToRem(25),
    lineHeight: lineHeight ?? 1.2,
  },
]);

export const IntroHeadline = styled(H1)({
  margin: theme.spacing(4.5, 'auto', 5),
});

// New Designs //////

export const H2V2 = styled.h2(({ fontSize, lineHeight }: TextStyle) => [
  AviatorRegular,
  {
    fontSize: fontSize ?? theme.typography.pxToRem(60),
    lineHeight: lineHeight ?? 1.2,
    color: lightBlack,
  },
]);
