import { primaryBlue } from 'shared/styles/colors';

const CheckmarkIcon = () => {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.86106 12.0439C5.72395 12.0434 5.58838 12.0148 5.4628 11.9597C5.33722 11.9047 5.2243 11.8244 5.13106 11.7239L0.271062 6.55386C0.0893891 6.36025 -0.00793272 6.1024 0.000506415 5.83704C0.00894555 5.57167 0.122454 5.32053 0.316062 5.13886C0.50967 4.95718 0.767518 4.85986 1.03288 4.8683C1.29825 4.87674 1.54939 4.99025 1.73106 5.18386L5.85106 9.57386L14.2611 0.373856C14.3464 0.267578 14.4526 0.17987 14.5731 0.116119C14.6935 0.0523679 14.8258 0.0139193 14.9617 0.00313494C15.0975 -0.00764942 15.2342 0.00945825 15.3632 0.0534069C15.4922 0.0973556 15.6109 0.167217 15.7119 0.258702C15.813 0.350186 15.8943 0.461361 15.9508 0.585398C16.0073 0.709436 16.0378 0.843717 16.0406 0.979994C16.0433 1.11627 16.0181 1.25167 15.9666 1.37787C15.9151 1.50407 15.8384 1.6184 15.7411 1.71386L6.60106 11.7139C6.5087 11.8162 6.39618 11.8984 6.27055 11.9552C6.14493 12.0119 6.00892 12.0421 5.87106 12.0439H5.86106Z"
        fill={primaryBlue}
      />
    </svg>
  );
};

export default CheckmarkIcon;
