import { useLocation } from 'react-router-dom';
import { IntroHeadline } from 'shared/components/Text';
import IntroPageGroup from 'shared/components/IntroPageGroup';
import TransitionLayout from 'shared/components/Layouts/TransitionLayout';
import PartyParrotsGif from 'shared/static/images/party-parrot.gif';

const PartyParrotIntro = () => {
  const { pathname } = useLocation();

  return (
    <TransitionLayout>
      <IntroPageGroup
        imagePath={PartyParrotsGif}
        path={`${pathname}/breakout-rooms`}
        headline={<IntroHeadline>Party Parrot</IntroHeadline>}
      />
    </TransitionLayout>
  );
};

export default PartyParrotIntro;
