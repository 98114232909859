export enum AnimationType {
  Initial = 'Initial',
  Exit = 'Exit',
  Enter = 'Enter',
}

export interface Delay {
  enterDelay?: number;
  exitDelay?: number;
}
