import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import {
  progressBarBeige,
  progressBarGreen,
  progressBarYellow,
} from 'shared/styles/colors';

interface Props {
  pointsCompleted: number;
  goal: number;
}
const totalWidth = 547;
const ProgressBar = ({ pointsCompleted, goal }: Props) => {
  const isSprintComplete = pointsCompleted >= goal && goal !== 0;

  return (
    <ProgressBarContainer>
      <ProgressBarFill
        animate={{
          width: isSprintComplete
            ? totalWidth
            : goal === 0
            ? 0
            : (pointsCompleted / goal) * totalWidth,
        }}
        transition={{ duration: 0.5 }}
        isFilled={isSprintComplete}
      />
    </ProgressBarContainer>
  );
};

export default ProgressBar;

const ProgressBarContainer = styled.div({
  height: 15,
  width: 547,
  backgroundColor: progressBarBeige,
  margin: '0 13px 10px 0',
});

const ProgressBarFill = styled(motion.div, {
  shouldForwardProp: (props) => props !== 'isFilled',
})(({ isFilled }: { isFilled: boolean }) => ({
  background: isFilled ? progressBarYellow : progressBarGreen,
  height: '100%',
  transition: '300ms linear',
}));
