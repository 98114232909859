import { primaryBlue } from 'shared/styles/colors';

interface Props {
  iconColor?: string;
}

const CloseIcon = ({ iconColor }: Props) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7043 13.4257L26.6393 2.49068C26.8851 2.20373 27.0135 1.83462 26.9989 1.4571C26.9843 1.07959 26.8278 0.721482 26.5607 0.45434C26.2935 0.187199 25.9354 0.0306993 25.5579 0.0161176C25.1804 0.00153586 24.8113 0.129946 24.5243 0.375685L13.5893 11.3107L2.65432 0.360685C2.36736 0.114945 1.99825 -0.0134631 1.62074 0.00111868C1.24322 0.0157004 0.885113 0.172198 0.617971 0.43934C0.35083 0.706482 0.19433 1.06459 0.179749 1.4421C0.165167 1.81962 0.293577 2.18873 0.539316 2.47568L11.4743 13.4257L0.524316 24.3607C0.367293 24.4952 0.239763 24.6606 0.149728 24.8467C0.0596929 25.0328 0.00909701 25.2355 0.00111777 25.4421C-0.00686147 25.6487 0.0279474 25.8547 0.103359 26.0472C0.178771 26.2397 0.293159 26.4145 0.439341 26.5607C0.585523 26.7068 0.760344 26.8212 0.952831 26.8966C1.14532 26.9721 1.35132 27.0069 1.5579 26.9989C1.76447 26.9909 1.96717 26.9403 2.15327 26.8503C2.33937 26.7602 2.50484 26.6327 2.63932 26.4757L13.5893 15.5407L24.5243 26.4757C24.8113 26.7214 25.1804 26.8498 25.5579 26.8353C25.9354 26.8207 26.2935 26.6642 26.5607 26.397C26.8278 26.1299 26.9843 25.7718 26.9989 25.3943C27.0135 25.0168 26.8851 24.6476 26.6393 24.3607L15.7043 13.4257Z"
        fill={iconColor ?? primaryBlue}
      />
    </svg>
  );
};

export default CloseIcon;
