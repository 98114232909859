import styled from '@emotion/styled';
import { black } from 'shared/styles/colors';

export const Circle = styled.div(
  ({ backgroundColor }: { backgroundColor?: string }) => ({
    width: 60,
    height: 60,
    borderRadius: 100,
    backgroundColor: backgroundColor ?? 'transparent',
    border: `2px solid ${black}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  })
);
