import type { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import theme from 'shared/styles/theme';
import { ButtonSecondary } from 'shared/components/Button';
import { Stack } from '@mui/material';
import { H1, Copy } from 'shared/components/Text';
import SplitLayout from 'shared/components/Layouts/SplitLayout';
import MainLayout from 'shared/components/Layouts/Main';
import { ROUTES } from 'shared/constants';

const TeamUpdate = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <>
        <SplitLayout>
          <Stack alignItems="center" maxWidth={550}>
            <H1 lineHeight={1.21}>Team Project Update + Q&A</H1>
            <Text>
              How's your project going? <br /> Any learnings to share?
            </Text>
            <ButtonSecondary onClick={() => navigate(ROUTES.TEAM_UPDATE.CHEER)}>
              Continue
            </ButtonSecondary>
          </Stack>
        </SplitLayout>
      </>
    </MainLayout>
  );
};

TeamUpdate.getLayout = function getLayout(page: ReactElement) {
  return <MainLayout>{page}</MainLayout>;
};

export default TeamUpdate;

const Text = styled(Copy)({
  margin: theme.spacing(2, 0, 6),
});
