import { gql } from '@apollo/client';

export const STREAKS = gql`
  query Streaks {
    streaks {
      projectName
      streakNumber
    }
  }
`;
