import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import Footer from 'shared/components/Footer';
import { white } from 'shared/styles/colors';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import {
  fadeIntoNextComponent,
  slideIntoNextComponent,
} from 'shared/styles/animations';
import { YetiLogo } from 'shared/components/icons';
import FooterV2 from '../FooterV2';

interface Props {
  children: ReactElement;
  useLogo?: boolean;
  useSlideTransition?: boolean;
}

export default function TransitionLayout({
  children,
  useLogo = false,
  useSlideTransition = false,
}: Props) {
  const { pathname } = useLocation();
  const controls = useAnimation();

  useEffect(() => {
    if (useSlideTransition && pathname === '/') {
      slideInSequence();
    }
  }, []);

  const slideInSequence = async () => {
    await controls.start(slideIntoNextComponent['initial']);
    return await controls.start(slideIntoNextComponent['slideIn']);
  };

  return (
    // <AnimatePresence exitBeforeEnter={false} initial={false}>
    <LayoutComponent
      initial={useSlideTransition && 'initial'}
      exit={useSlideTransition ? 'slideOut' : 'fadeOut'}
      animate={useSlideTransition && 'slideIn'}
      variants={
        useSlideTransition ? slideIntoNextComponent : fadeIntoNextComponent
      }
      key={pathname}
    >
      <MainComponent>
        {useLogo && (
          <LogoContainer>
            <YetiLogo height={67} width={167} />
          </LogoContainer>
        )}
        {children}
      </MainComponent>
      <FooterV2 />
    </LayoutComponent>
    // </AnimatePresence>
  );
}

const MainComponent = styled.main<{ backgroundColor?: string }>(
  ({ backgroundColor = white }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flex: 1,
    backgroundColor: backgroundColor,
    minHeight: 'calc(100vh - 62px)',
    overflow: 'hidden',
  })
);

const LayoutComponent = styled(motion.div)({
  minHeight: 'calc(100vh - 62px)',
  width: '100vw',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  justifyContent: 'center',
});

const LogoContainer = styled.div({
  position: 'absolute',
  top: 29,
  right: 72,
});
