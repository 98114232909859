import { gql } from '@apollo/client';

export const UPDATE_SPRINT_POINTS = gql`
  mutation UpdateSprintPoints(
    $sprintId: Int!
    $sprintDayId: Int!
    $totalPoints: Int
    $completedPoints: Int
  ) {
    updateSprintPoints(
      sprintId: $sprintId
      sprintDayId: $sprintDayId
      totalPoints: $totalPoints
      completedPoints: $completedPoints
    ) {
      totalPoints
      updatedAt
      completedPoints
    }
  }
`;

export const CREATE_NEW_SPRINT = gql`
  mutation CreateNewSprint {
    createNewSprints {
      id
    }
  }
`;
