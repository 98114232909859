import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import theme from 'shared/styles/theme';
import { ButtonSecondary } from 'shared/components/Button';
import { Stack } from '@mui/material';
import { H1, Copy } from 'shared/components/Text';
import SplitLayout from 'shared/components/Layouts/SplitLayout';
import MainLayout from 'shared/components/Layouts/Main';

interface BreakoutRoomsProps {
  path: string;
}

const BreakoutRooms = ({ path }: BreakoutRoomsProps) => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <>
        <SplitLayout>
          <Stack alignItems="center" maxWidth={550}>
            <H1 lineHeight={1.21}>Social Hang in Breakout Rooms</H1>
            <Text>
              What's up? Let's get to know each other. In a second you will be
              assigned to your breakout room.
            </Text>
            <ButtonSecondary onClick={() => navigate(path)}>
              Continue
            </ButtonSecondary>
          </Stack>
        </SplitLayout>
      </>
    </MainLayout>
  );
};

export default BreakoutRooms;

const Text = styled(Copy)({
  margin: theme.spacing(2, 0, 6.5),
});
