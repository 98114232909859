export const white = '#ffffff';
export const black = '#000000';
export const lightBlack = '#333333';

export const primaryBlue = '#2F6E7C';
export const secondaryBlue = '#F0F5F6';
export const tertiaryBlue = '#D8E5E7';

export const primaryDarkBlue = '#13373F';

export const primaryYellow = '#FAFE4E';

export const primaryGrey = '#ECECEC';

export const accentErrorRed = '#d32f2f';

export const summerOrange = '#D68A19';

export const progressBarGreen = '#A0EC8E';
export const progressBarYellow = '#FFD771';
export const progressBarBeige = '#DED399';
export const green = '#90FE69';

export const buttonGrey = '#4b463f';
export const lightBrown = '#AC8855';

export const lightRed = '#FF7070';
export const orange = '#FF9736';
export const yellow = '#FFF614';
export const limeGreen = '#A7FF37';
export const mintGreen = '#34FFB6';
export const lightBlue = '#43E8FF';
export const blue = '#3455FF';
export const purple = '#9542FF';
export const pink = '#E22FFF';
export const red = '#FF1B51';
export const beige = '#efe7d7';

// alphas
export const greyAlpha50 = 'rgba(238, 238, 238, 0.5)';
export const whiteAlpha40 = 'rgba(255, 255, 255, 0.4)';
export const lavenderUpdate = '#8F33D8C7';
