import styled from '@emotion/styled';
import { Dispatch, SetStateAction } from 'react';
import { ButtonSecondary } from '../Button';
import Modal from '../Modal';
import { H1, H1Alt, Subheading } from '../Text';
import PartyParrotsGif from 'shared/static/images/party-parrot.gif';
import { Streak } from '@adrenaline-v3/common/src/types';
import { motion } from 'framer-motion';
import {
  enterDownExitDown,
  enterLeftExitDown,
  enterUpExitDown,
} from 'shared/styles/animations';
import { AnimationType } from 'shared/styles/animations/types';
import { css } from '@emotion/react';
import { defaultTransition } from 'shared/styles/animations/constants';
import AirHornButton from '../AirHornButton';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<any>>;
  streaks: Streak[];
  onContinueClick: () => void;
}

const StreakWinnersModal = ({
  isModalOpen,
  setIsModalOpen,
  streaks,
  onContinueClick,
}: Props) => {
  const isSingleStreakWinner = streaks.length === 1;
  const animationDelay = 0.1;

  const { Enter, Initial } = AnimationType;

  return (
    <>
      <Modal
        modalContentStyles={ModalCSS}
        handleOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <TopSpacer />
        <Title
          variants={enterDownExitDown}
          transition={defaultTransition}
          custom={{ enterDelay: 0 }}
          animate={Enter}
          initial={Initial}
        >
          Party Parrot Streak Winner{isSingleStreakWinner ? '' : 's'}
        </Title>
        <OuterStreakContainer isSingleStreakWinner={isSingleStreakWinner}>
          {streaks.map((streak, index) => (
            <StreakContainer
              lessMargin={streaks.length > 6}
              variants={enterLeftExitDown}
              transition={defaultTransition}
              custom={{ enterDelay: animationDelay * (index + 1) }}
              animate={Enter}
              initial={Initial}
              key={`${streak.projectName}-${index}`}
            >
              <PartyParrotGif src={PartyParrotsGif} />
              <StreakLength>x{streak.streakNumber}</StreakLength>
              <ProjectName>{streak.projectName}</ProjectName>
            </StreakContainer>
          ))}
        </OuterStreakContainer>
        <AirHornButton />
        <Button
          variants={enterUpExitDown}
          transition={defaultTransition}
          custom={{ enterDelay: (streaks.length + 1) * animationDelay }}
          animate={Enter}
          initial={Initial}
          onClick={() => onContinueClick()}
        >
          Continue
        </Button>
      </Modal>
    </>
  );
};

export default StreakWinnersModal;

const ModalCSS = css({
  width: 904,
});

const TopSpacer = styled.div({
  height: 76,
});

const Title = styled(motion(H1))({
  maxWidth: 468,
  textAlign: 'center',
  marginBottom: 46,
});

const OuterStreakContainer = styled.div(
  ({ isSingleStreakWinner }: { isSingleStreakWinner: boolean }) => ({
    display: 'grid',
    gridTemplateColumns: isSingleStreakWinner ? '1fr' : '1fr 1fr',
    marginBottom: 21,
    width: 686,
    justifyItems: isSingleStreakWinner ? 'center' : 'left',
  })
);

const StreakContainer = styled(motion.div)(
  ({ lessMargin }: { lessMargin }) => ({
    display: 'flex',
    marginBottom: lessMargin ? 31 : 51,
  })
);

const PartyParrotGif = styled.img({
  height: 30,
});

const StreakLength = styled(Subheading)({
  marginRight: 25,
});

const ProjectName = styled(H1Alt)({
  fontSize: 30,
});

const Button = styled(ButtonSecondary)({
  marginBottom: 88,
});
