import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Dispatch, Fragment, SetStateAction } from 'react';
import Modal from 'shared/components/Modal';
import BackgroundImage from 'shared/static/images/background.png';
import AdrenalineLogoImage from 'shared/static/images/adrenaline-logo.png';
import { H2V2, MontserratBold, MontserratMedium } from 'shared/components/Text';
import { black, green, lightBlack } from 'shared/styles/colors';
import { colorArray, iconArray } from '../constants';
import { Spacer } from 'shared/components/Spacer';
import { Circle } from './styledComponents';
import CookiesService, { Identifiers } from 'services/Cookie.service';
import { useLiveEventsContext } from 'context/liveEvents';

interface Props {
  setIsOpen: Dispatch<SetStateAction<any>>;
  setUserColor: Dispatch<SetStateAction<any>>;
  setUserIcon: Dispatch<SetStateAction<any>>;
  setUserName: Dispatch<SetStateAction<any>>;
  isOpen: boolean;
  userColor: string;
  userName: string;
  userIcon: any;
}

const ConfigureProfileModal = ({
  setIsOpen,
  isOpen,
  userColor,
  userName,
  userIcon,
  setUserIcon,
  setUserColor,
  setUserName,
}: Props) => {
  const { setUserToFilter } = useLiveEventsContext();
  const closeModal = () => {
    if (userName && userIcon && userColor) {
      setIsOpen(false);
      setUserToFilter(userName);
      CookiesService.setCookie(Identifiers.UserColor, userColor);
      CookiesService.setCookie(Identifiers.UserName, userName);
      CookiesService.setCookie(Identifiers.UserIcon, userIcon);
    } else {
      alert('Finish configuring profile before saving');
    }
    // TODO add wallet logic with above values
  };
  return (
    <Modal
      modalContentStyles={modalStyles}
      handleOpen={isOpen}
      handleClose={closeModal}
      hideCloseButton
    >
      <TopBar>
        <PlaceHolder />
        <AdrenalineLogo src={AdrenalineLogoImage} />
        <LargeCircle backgroundColor={userColor}>
          <LargIcon src={userIcon} />
        </LargeCircle>
      </TopBar>
      <ConfigureText>Configure Profile</ConfigureText>
      <InputContainer>
        <Text>Name:</Text>
        <Input onChange={(e) => setUserName(e.target.value)} value={userName} />
      </InputContainer>
      <Text>Pick An Avatar:</Text>
      <Spacer height={11} width={11} />
      <IconContainer>
        {iconArray.map((icon, index) => (
          <Fragment key={`${icon}-${index}`}>
            <IconCircle onClick={() => setUserIcon(icon)}>
              <img src={icon} />
            </IconCircle>
          </Fragment>
        ))}
      </IconContainer>
      <Text>Choose Your Color:</Text>
      <Spacer height={29} width={29} />
      <ColorContainer>
        {colorArray.map((color, index) => (
          <Fragment key={index}>
            <Circle
              backgroundColor={color}
              onClick={() => setUserColor(color)}
            />
            <Spacer height={11} width={11} />
          </Fragment>
        ))}
      </ColorContainer>
      <SaveButtonContainer>
        <SaveButton onClick={closeModal}>SAVE</SaveButton>
      </SaveButtonContainer>
    </Modal>
  );
};

export default ConfigureProfileModal;

const modalStyles = css({
  maxHeight: 821,
  width: 945,
  backgroundImage: `url(${BackgroundImage})`,
  padding: 30,
  borderRadius: 0,
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
});

const TopBar = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const outerItemsCss = css({
  height: 105,
  width: 105,
});

const AdrenalineLogo = styled.img({});

const PlaceHolder = styled.div(outerItemsCss);

const Wallet = styled.img(outerItemsCss);

const ConfigureText = styled(H2V2)({
  fontSize: 32,
  color: black,
  marginBottom: 20,
});

const InputContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 27,
});

const Text = styled(H2V2)({
  fontSize: 24,
  color: black,
  marginRight: 17,
});

const Input = styled.input([
  MontserratMedium,
  {
    height: 34,
    width: 250,
    border: `2px solid ${lightBlack}`,
    background: 'transparent',
  },
]);

const SaveButtonContainer = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const SaveButton = styled.button([
  MontserratBold,
  {
    height: 44,
    width: 169,
    backgroundColor: green,
    fontSize: 16,
    cursor: 'pointer',
    border: `2px solid ${black}`,
  },
]);

const LargIcon = styled.img({
  height: '45%',
});

const LargeCircle = styled(Circle)({
  height: 105,
  width: 105,
  cursor: 'default',
});

const IconCircle = styled(Circle)({
  marginRight: 11,
  marginBottom: 11,
});

const ColorContainer = styled.div({
  display: 'flex',
  marginBottom: 41,
});

const IconContainer = styled.div({
  display: 'flex',
  marginBottom: 14,
  flexWrap: 'wrap',
});
